import React from 'react';

function getYear() {
  return new Date().getFullYear();
}

function Foot() {
  return (
    <div className="container">

      <div className="foot">
        <div id="bg7"></div>
        <div className="content-wrapper">

            <div className="footContent">
              <h3>©2019-{getYear()} Farzan Shenavar Masouleh</h3>
              <h3>ALL RIGHTS RESERVED</h3>
            </div>
          

         
        
        </div>
      </div>
    </div>
  )
}

export default Foot;
