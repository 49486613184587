import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Skills() {
  return (
    <div id="Skills" className="container">
        <div className="skills">
          <div id="bg3"></div>
          <div id="bg3-overlay"></div>
          <div className="skillsTeeth_Start">
            <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                  <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
            </svg>
          </div>
          <div className="content">
            <h1 className="skills_heading"> <i><FontAwesomeIcon icon="code" /></i> Skills <i><FontAwesomeIcon icon="code" /></i></h1>
            
            <div className="headerLine">
              <div className="thickLine"></div>
              <div className="slimLine"></div>
            </div>
            
            <div className="skillCardWrapper">

            <div className="skillCard">
                <img src="/static/AI.png"></img>
                <div className="cardText">
                  <h1>AI & Data</h1>
                  <hr></hr>
                  <div className="skillsName">
                  <ul>
                    <li><h3>Keras</h3></li>
                    <li><h3>TensorFlow</h3></li>
                    <li><h3>PyTorch</h3></li>
                    <li><h3>Scikit-Learn</h3></li>
                    <li><h3>Pandas</h3></li>
                    <li><h3>NumPy</h3></li>
                    <li><h3>OpenCV</h3></li>
                    <li><h3>Jupyter</h3></li>
                    <li><h3>Microsoft Fabric</h3></li>
                    <li><h3>Microsoft Copilot Studio</h3></li>
                    <li><h3>Microsoft Azure OpenAI</h3></li>
                    <li><h3>Apache Spark</h3></li>
                    <li><h3>Apache Kafka</h3></li>
                    <li><h3>Azure Databricks</h3></li>
                    <li><h3>Large Language Models (LLM)</h3></li>
                    <li><h3>Microsoft Azure Machine Learning</h3></li>
                    <li><h3>PowerBI</h3></li>
                  </ul> 
                  </div>
                </div>
              </div>

              <div className="skillCard">
                <img src="/static/tools.png"></img>
                <div className="cardText">
                  <h1>DevOps</h1>
                  <hr></hr>
                  <div className="skillsName">
                  <ul>
                    <li><h3>Docker</h3></li>
                    <li><h3>Nginx</h3></li>
                    <li><h3>Apache Web Server</h3></li>
                    <li><h3>Gitlab CI/CD</h3></li>
                    <li><h3>Jenkins</h3></li>
                    <li><h3>Proxmox</h3></li>
                    <li><h3>TrueNAS</h3></li>
                    <li><h3>Bash</h3></li>
                    <li><h3>GIT</h3></li>
                    <li><h3>Linux</h3></li>
                    <li><h3>Amazon AWS</h3></li>
                    <li><h3>Azure</h3></li>
                    <li><h3>Google Cloud</h3></li>
                    <li><h3>DigitalOcean</h3></li>
                  </ul> 
                  </div>
                </div>
              </div>

              <div className="skillCard">
                <img src="/static/back.png"></img>
                <div className="cardText">
                  <h1>Backend</h1>
                  <hr></hr>
                  <div className="skillsName">
                  <ul>
                    <li><h3>Flask</h3></li>
                    <li><h3>Django</h3></li>
                    <li><h3>ASP.NET</h3></li>
                    <li><h3>Node.js</h3></li>
                    <li><h3>Express</h3></li>
                    <li><h3>GIN</h3></li>
                  </ul> 
                  </div>
                </div>
              </div>

              <div className="skillCard">
                <img src="/static/front.png"></img>
                <div className="cardText">
                  <h1>Frontend</h1>
                  <hr></hr>
                  <div className="skillsName">
                  <ul>
                    <li><h3>React</h3></li>
                    <li><h3>NextJS</h3></li>
                    <li><h3>Angular4</h3></li>
                    <li><h3>Razor</h3></li>
                    <li><h3>Redux</h3></li>
                    <li><h3>SASS / SCSS</h3></li>
                    <li><h3>Webpack</h3></li>
                    <li><h3>Babel</h3></li>
                  </ul> 
                  </div>
                </div>
              </div>              

              <div className="skillCard">
                <img src="/static/db.png"></img>
                <div className="cardText">
                  <h1>Database</h1>
                  <hr></hr>
                  <div className="skillsName">
                  <ul>
                    <li><h3>MongoDB</h3></li>
                    <li><h3>PostgreSQL</h3></li>
                    <li><h3>Microsoft SQL Server</h3></li>
                    <li><h3>MySQL</h3></li>
                    <li><h3>Redis</h3></li>
                    <li><h3>Firebase</h3></li>
                    <li><h3>SQLAlchemy</h3></li>
                  </ul> 
                  </div>
                </div>
              </div>

              <div className="skillCard">
                <img src="/static/code.png"></img>
                <div className="cardText">
                  <h1>Languages</h1>
                  <hr></hr>
                  <div className="skillsName">
                  <ul>
                    <li><h3>Python</h3></li>
                    <li><h3>C#</h3></li>
                    <li><h3>Javascript / ES8</h3></li>
                    <li><h3>C/C++</h3></li>
                    <li><h3>Golang</h3></li>
                    <li><h3>Java</h3></li>
                    <li><h3>Matlab</h3></li>
                    <li><h3>Assembly</h3></li>
                    <li><h3>VHDL</h3></li>
                    <li><h3>Solidity (Ethereum)</h3></li>
                  </ul> 
                  </div>
                </div>
              </div>

            </div>

            <div className="skillsTeeth_End">
              <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
              </svg>
            </div>
          </div>
        
        </div>    
    </div>

  )
}

export default Skills;
