import React from 'react';
import Header_P from '../common/Header_P'
import ReactGA from 'react-ga4';

class P404 extends React.Component {
  state = {
    navigate: true
  }
  componentDidMount(){
    ReactGA.initialize('G-E8R3HWH7WE');
    ReactGA.event({
      category: 'testEvent',
      action: 'Website Viewed - 404'
    });

  }

  render() {
    return(
    <div className="container">
      <Header_P />
      <div className="p404">
        <h1>404 - Not Found!</h1>
      </div>
    </div>
    )
  }
}




export default P404;
