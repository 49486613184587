import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Edu() {
  return (
    <div id="Education" className="container">

      <div className="edu">
        <div id="bg2"></div>
        <div className="content-wrapper">
          <h1 className="eduHeader"> <i><FontAwesomeIcon icon="graduation-cap" /></i> Education <i><FontAwesomeIcon icon="graduation-cap" /></i></h1>
          
          <div className="headerLine">
            <div className="thickLine"></div>
            <div className="slimLine"></div>
          </div>

          <div className="edCard">
            <div>
              <i id="edSVG"><FontAwesomeIcon icon="laptop" /></i>
              <h2> Ph.D. in Computer Science</h2>
            </div>
            <div className="edInfo">
              <div>
                <i><FontAwesomeIcon icon="university" /></i>
                <span> University of Georgia (UGA)</span>
              </div>
              <div>
                <i><FontAwesomeIcon icon="map-marker-alt" /></i>
                <span> Athens, GA, United States</span>
              </div>
              <div>
                <i><FontAwesomeIcon icon="calendar-alt" /></i>
                <span> 2018 - 2022</span>
              </div>
            </div>
            <hr></hr>
            <div className="advisors">
            <h3><FontAwesomeIcon icon="user-tie" /> Advisors <FontAwesomeIcon icon="user-tie" /></h3>
            <div className="headerLine_ads">
              <div className="thickLine_ads"></div>
              <div className="slimLine_ads"></div>
            </div>

              <div className="advisor-wrapper">
              <div className="advisor">
                <img src="/static/arabnia.jpg"/>
                <div className="profName">Hamid R. Arabnia</div>
                <div>Professor Emeritus of Computer Science</div>
                <div>Major Professor</div>
                <a href="https://www.cs.uga.edu/directory/people/hamid-r-arabnia" target="_blank">Homepage</a>
              </div>
              <div className="advisor">
                <img src="/static/thiab.jpg"/>
                <div className="profName">Thiab Taha</div>
                <div>Professor of Computer Science</div>
                <div>Head of Computer Science Department</div>
                <a href="https://csci.franklin.uga.edu/directory/people/thiab-taha" target="_blank">Homepage</a>
              </div>
              <div className="advisor">
                <img src="/static/khaled.jpg"/>
                <div className="profName">Khaled Rasheed</div>
                <div>Professor of Computer Science</div>
                <div>Head of Artificial Intelligence Department</div>
                <a href="https://ai.uga.edu/directory/people/khaled-rasheed" target="_blank">Homepage</a>
              </div>
              </div>
            </div>

            <hr></hr>
            <div className="trophies">
              <div>
                <i><FontAwesomeIcon icon="trophy" /></i>
                <span> Summa Cum Laude Graduate (Cumulative GPA: 4.0)</span>
              </div>
              <div>
                <i><FontAwesomeIcon icon="trophy" /></i>
                <span> <b>Dissertation: </b> Complete Interpretable Lesion Detection Framework for Diabetic Retinopathy in the Era of the Internet of Things (IoT)</span>
              </div>
            </div>

          </div>

          <div className="edCard">
            <div>
              <i id="edSVG"><FontAwesomeIcon icon="laptop" /></i>
              <h2> Bachelor of Engineering in Computer Software</h2>
            </div>
            <div className="edInfo">
              <div>
                <i><FontAwesomeIcon icon="university" /></i>
                <span> Iran University of Science and Technology (IUST)</span>
              </div>
              <div>
                <i><FontAwesomeIcon icon="map-marker-alt" /></i>
                <span> Tehran, Tehran, Iran</span>
              </div>
              <div>
                <i><FontAwesomeIcon icon="calendar-alt" /></i>
                <span> 2014 - 2018</span>
              </div>
            </div>
            <hr></hr>
            <div className="trophies">
              <div>
                <i><FontAwesomeIcon icon="trophy" /></i>
                <span> Summa Cum Laude Graduate</span>
              </div>
              <div>
                <i><FontAwesomeIcon icon="trophy" /></i>
                <span> Member of Robotics Team</span>
              </div>
              <div>
                <i><FontAwesomeIcon icon="trophy" /></i>
                <span> Was offered a position for direct master’s degree</span>
              </div>
            </div>
          </div>
        
          {/* <div className="edCard">
          <div>
            <i id="edSVG"><FontAwesomeIcon icon="atom" /></i>
            <h2> Diploma in Mathematics and Physics</h2>
          </div>
          <div className="edInfo">
            <div>
              <i><FontAwesomeIcon icon="university" /></i>
              <span> National Organization for Development of Exceptional Talents (NODET)</span>
            </div>
            <div>
              <i><FontAwesomeIcon icon="map-marker-alt" /></i>
              <span> Rasht, Gilan, Iran</span>
            </div>
            <div>
              <i><FontAwesomeIcon icon="calendar-alt" /></i>
              <span> 2010 - 2014</span>
            </div>
          </div>
          <hr></hr>
          <div className="trophies">
            <div>
              <i><FontAwesomeIcon icon="trophy" /></i>
              <span> Summa Cum Laude Graduate</span>
            </div>
            <div>
              <i><FontAwesomeIcon icon="trophy" /></i>
              <span> Member of Robotics Team</span>
            </div>
            <div>
              <i><FontAwesomeIcon icon="trophy" /></i>
              <span> Member of ACM Team</span>
            </div>
          </div>
        </div> */}
        </div>
      </div>
    </div>
  )
}

export default Edu;
