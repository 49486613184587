import React, { Component } from 'react';
import Intro from './intro/Intro';
import Edu from './edu/Edu';
import Skills from './skills/Skills';
import Work from './work/Work';
import Teaching from './teaching/Teaching';
import Volun from './volun/Volun';
import Awards from './awards/Awards';
import Certs from './certs/Certs';
import Projs from './projs/Projs';
import Foot from './footer/Footer';
import Header from '../common/Header';
import ReactGA from 'react-ga4';

class Home extends Component {
  componentDidMount() {
    ReactGA.initialize('G-E8R3HWH7WE');
    ReactGA.event({
      category: 'testEvent',
      action: 'Website Viewed - XYZ'
    });

  }

  render() {
    return (
      <div className="container">
          <Header />
          <Intro />          
          <Work />
          <Skills />
          <Edu />
          <Teaching />
          <Volun />
          <Awards />
          <Certs />
          {/* <Projs /> */}
          <Foot />
      </div>
    );
  }
}

export default Home;