import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Volun() {
  return (
    <div id="Vol" className="container">

      <div className="vol">
        <div id="bg2"></div>
        <div className="content-wrapper">
          <h1> <i><FontAwesomeIcon icon={'users'} className="hide_on_small"/></i> Professional Activities <i><FontAwesomeIcon icon={'users'} className="hide_on_small" /></i></h1>
          
          <div className="headerLine">
            <div className="thickLine"></div>
            <div className="slimLine"></div>
          </div>

          <div className="volCardWrapper">

              <div>
                <h2>CSCE'23</h2>
                <h3>Co-Chair</h3>
                <h4>Las Vegas, NV, USA</h4>
                <p>July 2023</p>
              </div>
              <div>
                <h2>ICMLA AML-IoT FLAME 2021</h2>
                <h3>Publicity Chair</h3>
                <h4>Pasadena, CA, USA</h4>
                <p>December 2021</p>
              </div>
              <div>
                <h2>CSCE'21</h2>
                <h3>Executive | Session Chair | Developer | Designer</h3>
                <h4>Las Vegas, NV, USA</h4>
                <p>July 2021</p>
              </div>
              <div>
                <h2>CSCI'20</h2>
                <h3>Executive | Session Chair | Developer | Designer</h3>
                <h4>Las Vegas, NV, USA</h4>
                <p>December 2020</p>
              </div>

              <div>
                <h2>CSCE'20</h2>
                <h3>Session Chair</h3>
                <h4>Las Vegas, NV, USA</h4>
                <p>July 2020</p>
              </div>
              <div>
                <h2>CSCI'19</h2>
                <h3>Session Chair</h3>
                <h4>Las Vegas, NV, USA</h4>
                <p>December 2019</p>
              </div>
              <div>
                <h2>Science Bowl</h2>
                <h3>Executive</h3>
                <h4>University of Georgia</h4>
                <p>January 2019</p>
              </div>
              <div>
                <h2>Pycon</h2>
                <h3>Codirector and Executive</h3>
                <h4>Tehran, Tehran, Iran</h4>
                <p>2015, 2016 and 2017</p>
              </div>
              <div>
                <h2>Natural Language Processing Laboratory</h2>
                <h3>Software Tester</h3>
                <h4>Iran University of Science and Technology</h4>
                <p>Spring 2017</p>
              </div>
              
          </div>    
        
        </div>
      </div>
    </div>
  )
}

export default Volun;
