import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from "history";
import { createRoot } from 'react-dom/client';

import {
  BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import App from './components/App';
import Projects from './components/projects/Projects';
import P404 from './components/page404/P404';

import reducers from './reducers';

import './components/bundle.scss';

const store = configureStore({
  reducer: reducers,
});

const customHistory = createBrowserHistory();
  
  const root = createRoot(document.getElementById('react-root'));
  root.render(<Provider store={store}>
    <Router history={customHistory}>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="*" element={<P404 />}/>
      </Routes>
    </Router>
  </Provider>);