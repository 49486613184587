import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faResearchgate, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt, faAt, faQuoteLeft, faQuoteRight, faUniversity, faClock, faTrophy,
          faGraduationCap, faLaptop, faCalendarAlt, faAtom, faCode, faChalkboardTeacher,
          faIdCard, faFileAlt, faCodeBranch, faBars, faUserTie, faUsers } from '@fortawesome/free-solid-svg-icons'


library.add(fab, faMapMarkerAlt, faAt, faQuoteLeft, faQuoteRight, faUniversity, faClock, faTrophy, faGraduationCap,
              faLaptop, faCalendarAlt, faAtom, faCode, faChalkboardTeacher, faIdCard, faFileAlt,
              faCodeBranch, faBars, faUserTie, faGoogle, faResearchgate, faUsers)

import Home from "./home/Home";


class App extends Component {
  state = {
    loaded: false,
    remove: false,
  };

  isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;

  componentDidMount() {
    var self2 = this;
    setTimeout(function() {
      self2.setState({
        loaded: true,
      })
    }, 1000);
    setTimeout(function() {
      self2.setState({
        remove: true,
      })
    }, 2000);
  }

  render() {
    return (
      <div className="container">
          { (this.state.remove || this.isMobile)? "" : 
          <div id="preloader" className={ this.state.loaded ? "preloaded" : "" }>
            <div className="loader_line"></div>
          </div> }
      {/* {this.props.children} */}
      <Home />
      </div>
    );
  }


}

export default App;
