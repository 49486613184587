import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Awards() {
  return (
    <div id="Awards" className="container">

      <div className="awards">
        <div id="bg5"></div>
        <div className="teachingTeeth_Start">
            <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                  <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
            </svg>
        </div>
        <div className="content-wrapper">
          <h1> <i><FontAwesomeIcon icon="trophy" /></i> Awards <i><FontAwesomeIcon icon="trophy" /></i></h1>
          
          <div className="headerLine">
            <div className="thickLine"></div>
            <div className="slimLine"></div>
          </div>

          <div className="exCardWrapper">

            <ul>
              <li>
                <h2>Travel Grant</h2>
                <h4>The 2021 World Congress in Computer Science, Computer Engineering, & Applied Computing</h4>
                <p>Summer 2021</p>
              </li>
              <li>
                <h2>Travel Grant</h2>
                <h4>The 2020 International Conference on Computational Science & Computational Intelligence</h4>
                <p>Winter 2020</p>
              </li>
              <li>
                <h2>Travel Grant</h2>
                <h4>The 6th International Conference on Health Informatics and Medical Systems</h4>
                <p>Summer 2020</p>
              </li>
              <li>
                <h2>Travel Grant</h2>
                <h4>Georgia Oral and Facial Surgery</h4>
                <p>Summer 2020</p>
              </li>

              <li>
                <h2>POOIA Student Scholarship</h2>
                <h4>Professional Organization of Iranian Americans</h4>
                <p>Spring 2019</p>
              </li>
              <li>
                <h2>Outstanding Achievement Award</h2>
                <h4>6th Annual Conf. on Computational Science & Computational Intelligence</h4>
                <p>Spring 2019</p>
              </li>
              <li>
                <h2>Travel Grant</h2>
                <h4>Department of Computer Science, University of Georgia</h4>
                <p>Spring 2019</p>
              </li>
              <li>
                <h2>Travel Grant</h2>
                <h4>Graduate School, University of Georgia</h4>
                <p>Spring 2019</p>
              </li>
              <li>
                <h2>Registration Scholarship</h2>
                <h4>6th Annual Conf. on Computational Science & Computational Intelligence</h4>
                <p>Spring 2019</p>
              </li>
              <li>
                <h2>Honorary Master’s Degree Offer</h2>
                <h4>Iran University of Science and Technology</h4>
                <p>Spring 2018</p>
              </li>
              <li>
                <h2>National Game Analytic Dashboard Design Tournament</h2>
                <h3>2nd Place</h3>
                <h4>Iran</h4>
                <p>Fall 2017</p>
              </li>
              <li>
                <h2>Best Cognitive Science Association of Year</h2>
                <h4>Iran University of Science and Technology</h4>
                <p>Spring 2017</p>
              </li>
              <li>
                <h2>Best Teaching Assistant of Year</h2>
                <h4>Iran University of Science and Technology</h4>
                <p>Spring 2016</p>
              </li>
              <li>
                <h2>7 National ACM Contests</h2>
                <h3>1st Place</h3>
                <h4>Iran University of Science and Technology</h4>
                <p>2014-2018</p>
              </li>
              <li>
                <h2>2D Soccer Simulation Robotics League</h2>
                <h3>3rd Place</h3>
                <h4>Gilan University</h4>
                <p>Fall 2011</p>
              </li>
            </ul>
            
          </div>    
        
        </div>
        <div className="teachingTeeth_End">
              <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
              </svg>
          </div>
      </div>
    </div>
  )
}

export default Awards;
