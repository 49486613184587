import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Header extends Component {

    state={
        headerExpanded: false,
    }

    hideNav = () => {
        this.setState({
            headerExpanded: false,
        })    
    }

    render(){
        return (
            <header>
                <div className="header-area">
                    <ul id="nav_menu_visible">
                        <AnchorLink href='#About' offset="120"><li className="h_li"><span>About</span></li></AnchorLink>
                        <AnchorLink href='#Education' offset="150"><li className="h_li"><span>Education</span></li></AnchorLink>
                        <AnchorLink href='#Skills' offset="10"><li className="h_li"><span>Skills</span></li></AnchorLink>
                        <AnchorLink href='#Work' offset="120"><li className="h_li"><span>Work Experience</span></li></AnchorLink>
                        <AnchorLink href='#Teaching' offset="10"><li className="h_li"><span>Teaching Experience</span></li></AnchorLink>
                        <AnchorLink href='#Vol' offset="150"><li className="h_li"><span>Professional Activities</span></li></AnchorLink>
                        <AnchorLink href='#Awards' offset="40"><li className="h_li"><span>Awards</span></li></AnchorLink>
                        <AnchorLink href='#Certs' offset="200"><li className="h_li"><span>Certificates</span></li></AnchorLink>
                        {/* <AnchorLink href='#Projs'><li className="h_li"><span>Publications</span></li></AnchorLink> */}
                    </ul>
                </div>           

                <div className="header-area_acared">
                    <div className="header">
                        <div onClick={()=>{
                            if(this.state.headerExpanded){
                                this.setState({
                                    headerExpanded: false,
                                })    
                            } else{
                                this.setState({
                                    headerExpanded: true,
                                })
                            }
                        }}>
                            <div className="headerText">
                                <FontAwesomeIcon icon="bars" />
                            </div>
                        </div>
                    </div>
                    
                    { 
                    this.state.headerExpanded?
                    <div onClick={this.hideNav}>
                        <div className="acared_bg"></div>
                        <ul id="nav_menu_acared">
                            <AnchorLink href='#About' offset="120" onClick={this.hideNav}><li className="h_li"><span>About</span></li></AnchorLink>
                            <AnchorLink href='#Education' offset="150" onClick={this.hideNav}><li className="h_li"><span>Education</span></li></AnchorLink>
                            <AnchorLink href='#Skills' offset="10" onClick={this.hideNav}><li className="h_li"><span>Skills</span></li></AnchorLink>
                            <AnchorLink href='#Work' offset="120" onClick={this.hideNav}><li className="h_li"><span>Work Experience</span></li></AnchorLink>
                            <AnchorLink href='#Teaching' offset="10" onClick={this.hideNav}><li className="h_li"><span>Teaching Experience</span></li></AnchorLink>
                            <AnchorLink href='#Vol' offset="150" onClick={this.hideNav}><li className="h_li"><span>Professional Activities</span></li></AnchorLink>
                            <AnchorLink href='#Awards' offset="40" onClick={this.hideNav}><li className="h_li"><span>Awards</span></li></AnchorLink>
                            <AnchorLink href='#Certs' offset="200" onClick={this.hideNav}><li className="h_li"><span>Certificates</span></li></AnchorLink>
                            <AnchorLink href='#Projs' onClick={this.hideNav}><li className="h_li"><span>Publications</span></li></AnchorLink>
                        </ul>
                    </div> : ""
                    }
                </div>           
            </header>
        )
    }
}

export default Header;


