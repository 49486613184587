import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Intro() {
  return (
    <div id="About" className="container">
      
      <div className="introduce">
          <div id="bg"></div>
          <div id="bg-overlay"></div>
          <div className="content">
            <img src="/static/Farzan.jpg"></img>
            <h2>Farzan ShenavarMasouleh</h2>

            <div className="contactMe">
              <div className="cm_wrapper">
                <div><FontAwesomeIcon icon="at" />&nbsp;</div>
                <div> farzan.shenavarmasouleh@gmail.com</div>
              </div>
              <div className="cm_wrapper">
                  <div><FontAwesomeIcon icon="map-marker-alt" />&nbsp;</div>
                  <div> Suwanee, GA, United States</div>
              </div>

              <br />
              AI Researcher | Fullstack Web Developer | Data Engineer | Cloud Engineer | ML Engineer | Architect
            </div>
            
            <div className="aboutMe">
              <i><FontAwesomeIcon icon="quote-left" /></i>
              {/* <div>
              I'm a Fullstack Web Developer but wait there's more!
              I got my Ph.D. in Computer Science from the University of Georgia and I love to teach robots to See (Computer Vision/Image Processing), Talk (NLP), and Navigate (RL). How cool is that?! <br/>
              </div>
              <div>
                <br/>
              Thinking of a new software/research project and you have this irresistible feeling in your guts that I can make it even better? 
                Feel Free to Contact Me! (ಡ ͜ ʖ ಡ)
              </div> */}
              I got my Ph.D. in Computer Science from the University of Georgia and I love to teach robots to See (Computer Vision/Image Processing), Talk (Natural Language Processing), and Navigate (Reinforcement Learning). Consequently, I work with massive extensive datasets and develop efficient cloud pipelines as a solution to optimally process the underlying data and uncover hidden insights.
              <br /><br />
              Feel Free to Contact Me!
              <i><FontAwesomeIcon icon="quote-right" /></i>
            </div>
            
            
            {/* <div className="resBtn">
              <a href="/static/Resume_Farzan.pdf" onClick={ () => {
                ReactGA.event({
                  category: 'Resume',
                  action: 'Downloaded!'
                });
              } }>
                <div>
                  Download Resume
                </div>
              </a>
            </div> */}
            
            <div className="socials">
              <div>
                <a href="https://linkedin.com/in/farzansh" target="blank">
                  <i><FontAwesomeIcon icon={['fab', 'linkedin-in']} /></i>
                </a>
              </div>
              <div>
                <a href="https://github.com/Farzan-Sh" target="blank">
                  <i><FontAwesomeIcon icon={['fab', 'github']} /></i>
                </a>
              </div>
              <div>
                {/* <a href="https://scholar.google.com/citations?user=yy6mnaoAAAAJ&hl=en" target="blank">
                  <i><FontAwesomeIcon icon={['fab', 'google']} /></i>
                </a> */}
                <a href="https://scholar.google.com/citations?user=yy6mnaoAAAAJ&hl=en" target="blank">
                  <i><FontAwesomeIcon icon={['fab', 'google']} /></i>
                </a>
              </div>
              <div>
                <a href="https://www.researchgate.net/profile/Farzan_Shenavarmasouleh" target="blank">
                  <i><FontAwesomeIcon icon={['fab', 'researchgate']} /></i>
                </a>
              </div>
              
              <div>
                <a href="https://www.ratemyprofessors.com/ShowRatings.jsp?tid=2574079" target="blank">
                  <i><FontAwesomeIcon icon="chalkboard-teacher" /></i>
                </a>
              </div>
              
              {/* <div>
                <a href="https://facebook.com/FFPrime" target="blank">
                  <i><FontAwesomeIcon icon={['fab', 'facebook-f']} /></i>
                </a>
              </div> */}
            </div>

          </div>
      
          <div className="introTeeth">
            <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                  <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
            </svg>
          </div>
      </div>

      {/* <div id="filler"></div> */}
    </div>

  )
}
export default Intro;
