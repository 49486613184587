import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Certs() {
  return (
    <div id="Certs" className="container">

      <div className="cer">
        <div id="bg2"></div>
        <div className="content-wrapper">
          <h1> <i><FontAwesomeIcon icon="file-alt" /></i> Certificates <i><FontAwesomeIcon icon="file-alt" /></i></h1>
          
          <div className="headerLine">
            <div className="thickLine"></div>
            <div className="slimLine"></div>
          </div>

          <div className="volCardWrapper">
              <div>
                <h2>Network+</h2>
                <h4>Iran University of Science and Technology</h4>
                <p>Summer 2015</p>
              </div>
              <div>
              <h2>Certified Ethical Hacker (CEH) </h2>
                <h4>Iran University of Science and Technology</h4>
                <p>Summer 2015</p>
              </div>
              <div>
              <h2>Reinforcement Learning</h2>
                <h4>Iran University of Science and Technology</h4>
                <p>Summer 2016</p>
              </div>
              
          </div>    
        
        </div>
      </div>
    </div>
  )
}

export default Certs;
