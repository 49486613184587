import React from 'react';
import { Link } from 'react-router-dom'

function Header_P() {
  return (
    <header>
        <div className="header-area_p">
            <div className="container">
                <div className="row align-items-center">
                    <div className="">
                        <div className="main-menu">
                            <nav className="nav-menu">
                                <ul id="nav_menu_visible">
                                    <Link to="/"><li><span>Back To Main Page</span></li></Link>
                                </ul>
                            </nav>
                        </div>           
                    </div>
                </div>
            </div>
        </div>
    </header>
  )
}

export default Header_P;


