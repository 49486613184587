import React from 'react';
import Header_P from '../common/Header_P'
import ReactGA from 'react-ga4';

function Projects() {
  ReactGA.initialize('G-E8R3HWH7WE');
  ReactGA.event({
    category: 'testEvent',
    action: 'Website Viewed - Projects'
  });

  return (
    <div className="container">
      <Header_P />
      <div className="projects">
        <h1>I'm Still Working On This Page, There Are Too Many! :D</h1>
      </div>
      
    </div>
  )
}

export default Projects;
