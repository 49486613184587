import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Teaching() {
  return (
    <div id="Teaching" className="container">

      <div className="teach">
        <div id="bg5"></div>
        <div className="teachingTeeth_Start">
            <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                  <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
            </svg>
        </div>
        <div className="content-wrapper">
          <h1> <i><FontAwesomeIcon icon="chalkboard-teacher" className="hide_on_small" /></i> Teaching Experience <i><FontAwesomeIcon icon="chalkboard-teacher" className="hide_on_small" /></i></h1>
          
          <div className="headerLine">
            <div className="thickLine"></div>
            <div className="slimLine"></div>
          </div>

          <div className="exCardWrapper">

            <ul>
              <li>
                <h2>Introduction to Computing and Programming (Java)</h2>
                <h3>Lab Instructor</h3>
                <h4>University of Georgia</h4>
                <p>Spring 2022</p>
              </li>
              <li>
                <h2>Data Science II</h2>
                <h3>Graduate Teaching Assistant</h3>
                <h4>University of Georgia</h4>
                <p>Fall 2021</p>
              </li>
              <li>
                <h2>Computer Graphics</h2>
                <h3>Graduate Teaching Assistant</h3>
                <h4>University of Georgia</h4>
                <p>Fall 2021</p>
              </li>
              <li>
                <h2>Discrete Mathematics</h2>
                <h3>Graduate Teaching Assistant</h3>
                <h4>University of Georgia</h4>
                <p>Spring 2021</p>
              </li>
              <li>
                <h2>Discrete Mathematics</h2>
                <h3>Graduate Teaching Assistant</h3>
                <h4>University of Georgia</h4>
                <p>Fall 2020</p>
              </li>
              <li>
                <h2>Discrete Mathematics for Engineering</h2>
                <h3>Instructor</h3>
                <h4>University of Georgia</h4>
                <p>Spring 2020</p>
              </li>
              <li>
                <h2>Cyber Security</h2>
                <h3>Graduate Teaching Assistant</h3>
                <h4>University of Georgia</h4>
                <p>Fall 2019</p>
              </li>
              <li>
                <h2>Data Structures</h2>
                <h3>Graduate Teaching Assistant</h3>
                <h4>University of Georgia</h4>
                <p>Summer 2019</p>
              </li>
              <li>
                <h2>Foundations for Informatics and Data Analytics</h2>
                <h3>Graduate Teaching Assistant</h3>
                <h4>University of Georgia</h4>
                <p>Fall 2018, Spring 2019, Summer 2019 and Fall 2019</p>
              </li>
              <li>
                <h2>Discrete Mathematics for Computer Science</h2>
                <h3>Graduate Teaching Assistant</h3>
                <h4>University of Georgia</h4>
                <p>Fall 2018 and Spring 2019</p>
              </li>
              <li>
                <h2>Microprocessors and Assembly</h2>
                <h3>Teaching Assistant</h3>
                <h4>Iran University of Science and Technology</h4>
                <p>Spring 2016, Fall 2017 and Spring 2018</p>
              </li>
              <li>
                <h2>Database Design</h2>
                <h3>Teaching Assistant</h3>
                <h4>Iran University of Science and Technology</h4>
                <p>Spring 2017</p>
              </li>
              <li>
                <h2>Advanced Programming with Python</h2>
                <h3>Teaching Assistant</h3>
                <h4>Iran University of Science and Technology</h4>
                <p>Fall 2015</p>
              </li>
              <li>
                <h2>Foundations for Programming with Python</h2>
                <h3>Teaching Assistant</h3>
                <h4>Iran University of Science and Technology</h4>
                <p>Spring 2015</p>
              </li>
            </ul>
            
          </div>    
        
        </div>
        <div className="teachingTeeth_End">
              <svg x="0px" y="0px" viewBox="0 186.5 1920 113.5">
                    <polygon points="0,300 655.167,210.5 1432.5,300 1920,198.5 1920,300 "></polygon>
              </svg>
          </div>
      </div>
    </div>
  )
}

export default Teaching;
